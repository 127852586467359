import {React} from "react";
import {useParams} from 'react-router-dom'
import useCallApi from "../hooks/useCallApi";

function Certificate(){
    
    const params = useParams()
    const { getCall } = useCallApi();
    const BASE_URL = process.env.REACT_APP_API_URL;

    
    const linkedin = async () => {
        const data = await getCall("Certificate/" + params.code)  
        let date = new Date(data.dateCertificate);
        let month = date.getMonth()+1;
        window.open("https://www.linkedin.com/profile/add?"+data.courseName+"&name="+data.courseName+"&organizationId=81418679&issueYear="+date.getFullYear()+"&issueMonth="+month+"&certUrl=https://it-arg.com/certificado/"+data.code+"&certId="+data.code, '_blank');
    }

    const cursorCol ={
        cursor: "pointer"
    };

    return(
        <main className="container text-center">
            <img className="w-75 mt-5" src={BASE_URL + "imgCertificates/" + params.code + ".jpg"} alt="certificado"></img>
            <div className="row justify-content-evenly mt-5 ">
                <div className="col-md-2 col-5">
                    <a href={BASE_URL + "Certificate/downloadCertificate/" + params.code} style={cursorCol} download className="text-white text-decoration-none">
                        <img style={cursorCol} className="w-25 " src="../img/download.png" />
                        <br/>
                        Descargar
                    </a>
                </div>
                <div className="col-md-2 col-5 cursor-col">
                    <img style={cursorCol} onClick={linkedin} alt="linkedin" className="w-25" src="../img/logo-linkedin-icon.png" />
                    <br/> <p style={cursorCol} onClick={linkedin} className="text-white" >Licencias y certificados</p>
                </div>                
            </div>
        </main>
    )
}
export default Certificate;